



























import { defineComponent, SetupContext } from '@vue/composition-api'

import { CustomIcon } from '../Icon'
import { CustomParagraph } from '../Paragraph'

import CloseIcon from './partials/CloseIcon.vue'
import { AlertProps } from './Alert.contracts'
import { alertProps, useAlert } from './Alert.hooks'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
export const Alert = defineComponent({
  name: 'Alert',
  components: { CloseIcon, CustomParagraph, CustomIcon },
  props: alertProps,
  setup (props: AlertProps, ctx: SetupContext) {
    return useAlert(props, ctx.emit)
  }
})
export default Alert
